import React, { Fragment } from "react";
import "../.././Redirect.css";

function Redirect() {
  return (
    <div className='redirect'>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"></meta>
      <head>
        <title>Tyler MacLean</title>
      </head>
      <body class="title">
        <div>
          <h1 className="montserratLarge spaceLeft">MacLean Software Solutions</h1>
        </div>
        <div class="parent">
<div class="div1"> 
<img
            src={process.env.PUBLIC_URL + "/Headshot2024.png"}
            align="left"
            className="roundish spaceLeft headshot"
          ></img>
</div>
<div class="div2">
          <h1 align="left" valign="top" className="montserrat">
            Tyler MacLean
          </h1>
        <h1 align="left" valign="top" className="montserrat">
            Adaptable Software Developer
          </h1>
          <div class="montserrat_small">Creating: API integrations, React web apps, versitile software, automation, visual regression, testing solutions and more. </div>
       
         <div className='bottomleft'>
          <p className="montserrat"> Quick Links:</p>
        </div>
</div>


</div>
<div className='center' width="vw">
          <a href="https://github.com/Tmaclea6">
            <img
              class="roundish headshot"
              src={process.env.PUBLIC_URL + "/github.png"}
            />
          </a>
          <a href="https://www.linkedin.com/in/tylerdmaclean/">
            <img
              class="roundish headshot"
              src={process.env.PUBLIC_URL + "/LinkedIn_logo_initials.png"}
            />
          </a>
          <a href="/Plangame">
            <img
              class="roundish headshot"
              src={process.env.PUBLIC_URL + "/PlanGame.png"}
            />
          </a>
          <a href="/HeresTheScoop">
            <img
              class="roundish headshot"
              src={process.env.PUBLIC_URL + "/Heresthescoop.png"}
            />
          </a>
          <a href="/SailFast">
            <img
              class="roundish headshot"
              src={process.env.PUBLIC_URL + "/Sailfast Logo1.png"}
            />
          </a>
        </div>
        <div class="parent1 margin-right">
<div class="div3 montserrat spaceLeft margin-right"> Professional Projects</div>
<div class="div4 montserrat_small spaceLeft margin-right"> IBM Qradar Modernization Project, Inventory Managememnt Software, Shopify API, Gorgias API , Help Desk API, Quick Books Automation </div>
<div class="div5 montserrat spaceLeft">Personal Projects </div>
<div class="div6 montserrat_small spaceLeft margin-right">Sail Fast: online tack and polar velocity calculator, Here's the Scoop: retail Ice cream stand web page, Plan Game: online task organizer app, Thermal Camera bluetooth monitor with ESP32</div>
<div class="div7 montserrat spaceLeft">Contact</div>
<div class="div8 montserrat_small spaceLeft margin-right">Tyler.MacLean2000@gmail.com</div>
</div>
      </body>
    </div>
  );
}
export default Redirect;
