import React, { useEffect, useState } from 'react';
import { Map, Marker } from 'pigeon-maps';
import '../.././SailFast.css'; // You can create this file for custom styles

const SailFast = () => {
  const [userLocation, setUserLocation] = useState([0, 0]);
  const [startCoords, setStartCoords] = useState([0, 0]);
  const [destCoords, setDestCoords] = useState([0, 0]);
  const [error, setError] = useState(null);
  const [zoom, setZoom] = useState(12); // Default zoom level
  const [isModalOpen, setIsModalOpen] = useState(false);
  const initialCenter = [45.9100, -66.0900]; // You can set a default center if needed
  const [rows, setRows] = useState([{ windSpeed: '', closeHaul: '', closeReach: '', beamReach: '', broadReach: '', trailingRun: '', run: '' }]); // Initial row\
  const EARTH_RADIUS = 6371; // Radius of Earth in kilometers
  const [resultsString, setResultsString] = useState('');

  const [locations, setLocations] = useState([{name: 'Start line Starboard End', x: '45.910078', y: '-66.087778'},
                                              {name: 'Start line Port End', x: '45.909363', y: '-66.086667'},
                                              {name: 'Grand Pt Bight', x: '45.91055', y: '-66.080556'},
                                              {name: 'Whites Cove', x: '45.871937', y: '-66.073056'},
                                              {name: 'Robertson Pt', x: '45.879713', y: '-66.109444'},
                                              {name: 'Scotchtown', x: '45.897972', y: '-66.125833'},
                                              {name: 'Regatta Mark', x: '45.893988', y: '-66.1'},
                                              {name: 'Jemseg Fairway Bouy (Sled)', x: '45.871717', y: '-66.137222'}
                                            ]);
  const sailPositions = {
    closeHaul: { port: 30, starboard: 330 }, // Close Haul angles from the wind
    closeReach: { port: 70, starboard: 290 }, // Close Reach angles from the wind
    beamReach: { port: 90, starboard: 270 }, // Beam Reach angles from the wind
    broadReach: { port: 110, starboard: 250 }, // Broad Reach angles from the wind
    trailingRun: { port: 150, starboard: 210 }, // Trailing Run angles from the wind
    run: { port: 180, starboard: 180 } // Run angles from the wind
  };

  const sailPosArray = [30, 70, 90, 110, 150, 180, 180, 210, 250, 270, 290, 330];

  const [start, setStart] = useState(null);
  const [destination, setDestination] = useState(null);

    // Wind data state
  const [windDirection, setWindDirection] = useState('');
  const [windSpeed, setWindSpeed] = useState('');

  // Select start toggle
  const [selectStartOn, setSelectStartOn] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const addRow = () => {
    setRows([...rows, { windSpeed: '', closeHaul: '', closeReach: '', beamReach: '', broadReach: '', trailingRun: '', run: '' }]);
  };

  const selectSpeedRow = () => {

  };

  const locationTooCoords = (location) => {
    return [parseFloat(location.x), parseFloat(location.y)];
  }
  const handleLocationClick = (location) => {
    if (selectStartOn) {
      if(destination) {
        setStart(location);
        setStartCoords(locationTooCoords(location));
        setDestination(null);
      }else if (!start) {
        setStart(location);
        setStartCoords(locationTooCoords(location));
      } else {
        setDestination(location);
        setDestCoords(locationTooCoords(location));
      }
    } else {
      setStart(userLocation);
      setDestination(location);
      setDestCoords(locationTooCoords(location));
    }
  };

  function decimalToTime(decimalHours) {
    // Get the whole hours (integer part)
    const hours = Math.floor(decimalHours);
    
    // Get the minutes (decimal part * 60)
    const minutes = Math.floor((decimalHours - hours) * 60);
    
    // Get the seconds (decimal part of minutes * 60)
    const seconds = Math.round(((decimalHours - hours) * 60 - minutes) * 60);
  
    // Format hours, minutes, and seconds as HH:MM:SS
    const formattedHours = String(hours).padStart(2, '0');  // Add leading zero if needed
    const formattedMinutes = String(minutes).padStart(2, '0');  // Add leading zero if needed
    const formattedSeconds = String(seconds).padStart(2, '0');  // Add leading zero if needed
  
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  // this function undershoots the interpolation paramiters and gives values closer to the middle of the oulying data
  const interpolateVelocities = (velocityArray, inputVelocity) => { 
    const A0 = velocityArray.map(row => parseFloat(row[0])); // Extract the first column
    const sortedIndices = A0.map((v, i) => [parseFloat(v), i]).sort((a, b) => a[0] - b[0]);
    
    let lowerIndex = -1;
    let upperIndex = -1;
  
    // Find the closest velocities
    for (let i = 0; i < sortedIndices.length; i++) {
      const [velocity, index] = sortedIndices[i];
      if (velocity === inputVelocity) {
        // Exact match found
        return velocityArray[index]; // Return the corresponding row
      } else if (velocity < inputVelocity) {
        lowerIndex = index;
      } else if (velocity > inputVelocity && upperIndex === -1) {
        upperIndex = index;
        break;
      }
    }
  
    // If no lower or upper indices are found, return an empty array
    if (lowerIndex === -1 || upperIndex === -1) {
      return [];
    }
  
    // Get the velocities for the lower and upper bounds
    const lowerVelocityRow = velocityArray[lowerIndex];
    const upperVelocityRow = velocityArray[upperIndex];
  
    // Calculate the proportion based on the input velocity
    const lowerVelocity = A0[lowerIndex];
    const upperVelocity = A0[upperIndex];
  
    const totalRange = upperVelocity - lowerVelocity;
    const inputPosition = inputVelocity - lowerVelocity;
  
    // Calculate the proportion
    const proportion = inputPosition / totalRange;
    console.log(proportion);
  
    // Create the resulting weighted array
    const result = lowerVelocityRow.map((val, idx) => {
      var ProportionalAddition = (upperVelocityRow[idx] - val) * proportion;
      return val+ProportionalAddition;
      
    });
  
    return result;
  };

  const calculateHeading = (windDirection, position) => {
    const angles = sailPositions[position];
    if (!angles) {
      throw new Error('Invalid sail position');
    }
    // Calculate the headings based on the wind direction
    const portHeading = (windDirection + angles.port) % 360;
    const starboardHeading = (windDirection + angles.starboard) % 360;
  
    return { portHeading, starboardHeading };
  };

  const generateEquidistantNumbers = (numbers) => {
    const result = [];
  
    for (let i = 0; i < numbers.length - 1; i++) {
      const start = numbers[i];
      const end = numbers[i + 1];
  
      if (start === end) {
        // If numbers are equal, push the same number 8 times
        result.push(...Array(8).fill(start));
      } else {
        const step = (end - start) / 9; // 9 segments for 8 equidistant numbers
        for (let j = 0; j < 9; j++) {
          result.push(start + j * step);
        }
      }
    }
    return result;
  }

  const heuristic = (pos, goal) => {
    return Math.sqrt(Math.pow(goal.x - pos.x, 2) + Math.pow(goal.y - pos.y, 2));
  };
  function roundDownAngle(angle) {
    // Use modulo to bring the angle within the 0° to 360° range
    let roundedAngle = angle % 360;

    // If the result is negative, adjust it to be positive
    if (roundedAngle < 0) {
        roundedAngle += 360;
    }

    return roundedAngle;
}
  
  const aStarSearch = (start, end, velocities, angles) => {
    const openSet = [];
    const closedSet = new Set();
    const startNode = { position: start, g: 0, h: heuristic(start, end), angle: null };
    openSet.push(startNode);
  
    const results = [];
  
    while (openSet.length > 0) {
      // Sort open set by lowest cost (g + h)
      openSet.sort((a, b) => (a.g + a.h) - (b.g + b.h));
      const currentNode = openSet.shift();
  
      // Check if we reached the end
      if (Math.hypot(currentNode.position.x - end.x, currentNode.position.y - end.y) < 1) {
        results.push({ position: currentNode.position, angle: currentNode.angle, time: currentNode.g });
        break;
      }
  
      closedSet.add(`${currentNode.position.x},${currentNode.position.y}`);
  
      // Explore possible movements
      for (let i = 0; i < velocities.length; i++) {
        const velocity = velocities[i];
        const angle = angles[i];
  
        // Calculate new position
        const radians = (angle * Math.PI) / 180;
        const newPosition = {
          x: currentNode.position.x + Math.cos(radians) * velocity,
          y: currentNode.position.y + Math.sin(radians) * velocity,
        };
  
        const newG = currentNode.g + (1 + (currentNode.angle !== angle ? 5 : 0)); // Add penalty for direction change
        const newH = heuristic(newPosition, end);
  
        // Check if already evaluated
        if (closedSet.has(`${newPosition.x},${newPosition.y}`)) continue;
  
        // Check if the new node is already in open set
        const existingNode = openSet.find(node => node.position.x === newPosition.x && node.position.y === newPosition.y);
        if (!existingNode || newG < existingNode.g) {
          const newNode = { position: newPosition, g: newG, h: newH, angle: angle };
          openSet.push(newNode);
          if (!existingNode) results.push(newNode); // Store path
        }
      }
    }
  
    return results;
  };

  function calculateComponents(angle, distance, isDegrees = true) {
    // Convert angle to radians if it's in degrees
    if (isDegrees) {
        angle = angle * (Math.PI / 180);  // Convert degrees to radians
    }

    // Calculate the x and y components using trigonometry
    const x = distance * Math.cos(angle);
    const y = distance * Math.sin(angle);

    return { x, y };
  }
  function relativeAngle(theta1, theta2, degrees = true) {
    // If the angles are in degrees, convert them to radians for calculation
    if (degrees) {
        theta1 = theta1 * Math.PI / 180;
        theta2 = theta2 * Math.PI / 180;
    }

    // Calculate the relative angle in radians
    let relativeAngle = (theta1 - theta2 + Math.PI) % (2 * Math.PI) - Math.PI;

    // If the result should be in degrees, convert back to degrees
    if (degrees) {
        relativeAngle = relativeAngle * 180 / Math.PI;
    }

    return relativeAngle;
}
  //cardinal angles not angles internal to the triangle
  function dataFromAngles(end, theta1, theta2) {
    theta2 = Math.abs(relativeAngle(theta1,theta2));
   
    //cardinal angles not 
    const AB = distanceBetweenPoints(0, 0, end.x, end.y);

  // Convert angles to radians (as Math.sin and Math.cos expect radians)
  const theta1Rad = theta1 * Math.PI / 180;
  const theta2Rad = theta2 * Math.PI / 180;
  console.log("theta1: "+theta1Rad);
  console.log("theta2: "+theta2Rad);
  // Use the Law of Sines to calculate AC and BC
  const sumAngles = theta1Rad + theta2Rad;

  // Calculate side AC using the Law of Sines
  const AC = Math.abs((AB * Math.sin(theta2Rad)) / Math.sin(sumAngles));

  console.log(AC);

  // Calculate side BC using the Law of Sines
  const BC =   Math.abs((AB * Math.sin(theta1Rad)) / Math.sin(sumAngles));

  // Return the calculated sides AC and BC
  return { AC, BC };

  }

  function getSides(end, angle1, angle2) {

    const x = end.x;
    const y = end.y;
    // Convert angles from degrees to radians
    const angle1Rad = angle1 * Math.PI / 180;
    const angle2Rad = angle2 * Math.PI / 180;

    // Calculate the vector of the first angle (from (0, 0) to (x, y))
    const vector1 = { x: Math.cos(angle1Rad), y: Math.sin(angle1Rad) };

    // Calculate the vector of the second angle (from (0, 0) to some point along angle2)
    const vector2 = { x: Math.cos(angle2Rad), y: Math.sin(angle2Rad) };

    // Calculate the distance from (0, 0) to (x, y) - side 'a'
    const a = Math.sqrt(x * x + y * y);

    // Check if the two angles can indeed form a triangle by looking at the dot product
    const dotProduct = vector1.x * vector2.x + vector1.y * vector2.y;
    const magnitude1 = Math.sqrt(vector1.x * vector1.x + vector1.y * vector1.y);
    const magnitude2 = Math.sqrt(vector2.x * vector2.x + vector2.y * vector2.y);
    const cosTheta = dotProduct / (magnitude1 * magnitude2);

    // Ensure the cosine value is within the valid range for arccos ([-1, 1])
    const angleBetween = Math.acos(Math.max(-1, Math.min(1, cosTheta)));

    // If the angle between the vectors is not feasible (i.e., greater than 180 degrees), return an error
    if (angleBetween > Math.PI) {
        return "Invalid angles, cannot form a triangle.";
    }

    // Calculate the distance between (x, y) and the point at angle2 - side 'b'
    const b = Math.sqrt(Math.pow(x - vector2.x * a, 2) + Math.pow(y - vector2.y * a, 2));

    // Return the sides 'a' and 'b' along with the angle between the vectors
    return {
        AC: a,
        BC: b,
        angleBetweenVectors: angleBetween * 180 / Math.PI // Convert back to degrees
    };
}

  // V = D/t  t = D/v
  //stagger the search so it takes a cardenel rotation in each angle and halfs between the best results
  function cardinalSearch(end, velocities, angles) {
    //let cardinalAngles = [0,45,90,135,180,225,270,315];
    let bestCurrentTime = {t:1000000000000000000, a1:null, a2:null,ta1:null, ta2:null,da1:null,da2:null};
    console.log(angles);
    console.log(velocities);
    for (let i = 0; i<angles.length; i++) {
      for(let j = 0; j < angles.length; j++) {
        //console.log(end);
        let out = getSides(end,angles[i],angles[j]);
        if (out == "Invalid angles, cannot form a triangle.") {
          continue;
        }
        // AC = AC >= 0 ? AC : AC*-1;
        // AC = BC >= 0 ? BC : BC*-1;
        console.log(out);
         let tac = Math.abs(out.AC/velocities[j]);
         let tbc = Math.abs(out.BC/velocities[i]);
         let tt = Math.abs(tac) + Math.abs(tbc);
        bestCurrentTime = tt < bestCurrentTime.t ? {t:tt, a1:i, a2:j,ta1:tbc, ta2:tac,da1:out.BC,da2:out.AC} : bestCurrentTime;
      }

    }
    console.log(angles.length);
    console.log(velocities.length);
    
    return bestCurrentTime;
  }
  function calculateOptimalPath(start, end, velocities, angles) {
    const timePenalty = 2; // 2-second penalty for changing angles
    const results = [];
    const compResults = [];
    const maxPartitions = 4;

    // Calculate the total distance between start and end points

    var destd = latLngToXY(start[0],start[1], end[0], end[1]);
    console.log(start);
    console.log(destd);
    
    let lastAngle = null;
    for (let j = 1; j <= maxPartitions; j++) {
      var interval = destd.distance/j;
      let currentPoint = {x :0, y:0};
      let bestVal = Infinity;
      let topd = {x :Infinity, y:Infinity, time:Infinity, angle:NaN, velocity:0};
      for (let i = 0; i < velocities.length; i++) {
          const angle = angles[i];
          const velocity = velocities[i];
          //d = tv
          var div = calculateComponents(angle,interval)
          var point = {x:div.x+currentPoint.x, y:div.y + currentPoint.y};
          var compTime = distanceBetweenPoints(point.x,point.y, destd.x, destd.y)/velocity;
          if(compTime < bestVal){
           
            bestVal = compTime;
            topd.a = angle;
            topd.t = compTime;
            topd.x = point.x;
            topd.y = point.y;
            topd.v = velocity;
          }
       
      }
      results.push({
        angle: topd.a,
        distance: topd.t*topd.v,
        totalTime: topd.t,
      });
    }

    return results;
}

const handleCalculate = () => {
    //console.log(calculateHeading(0,'run'));
    if (selectStartOn && !destination) {
      setError('Destination cannot be null when selecting start!');
      return;
    }
    setError('');
    // Add your calculation logic here
    //console.log('Calculating from:', start, 'to:', destination);

    let velocities = interpolateVelocities(rows.map(o => [parseFloat(o.windSpeed), parseFloat(o.closeHaul), parseFloat(o.closeReach), parseFloat(o.beamReach), parseFloat(o.broadReach), parseFloat(o.trailingRun), parseFloat(o.run)]), parseFloat(windSpeed));
    
    //console.log(velocities);
    velocities.shift();
    //console.log(velocities)
    // console.log('winddirection');
    // console.log(windDirection)
    const reversedArray = [...velocities].reverse()
    let vectors = generateEquidistantNumbers(velocities.concat(reversedArray));
     //vectors = velocities.concat(reversedArray);
    // console.log('vectors');
    // console.log(vectors);
    // console.log(vectors);
    // console.log('sailposarray');
    // console.log(sailPosArray);
    
    let angles = generateEquidistantNumbers(sailPosArray.map(item => roundDownAngle( parseFloat(item)+parseFloat(windDirection))));
     //angles =sailPosArray.map(item => roundDownAngle( parseFloat(item)+parseFloat(windDirection)));
    //console.log('angles');
    //console.log(angles);
    var destd = latLngToXY(startCoords[0],startCoords[1], destCoords[0], destCoords[1]);
    //console.log(dataFromAngles({x:14, y:17},45,224));
    let result = cardinalSearch(destd, vectors, angles);
    //console.log(result);
    setResultsString('Estimated Results: \n\n Heading 1:\n'+result.a1+'° for '+result.da1.toFixed(2)+'NM or '+decimalToTime(result.ta1)+'s\n\n Heading 2:\n'+result.a2+'° for '+result.da2.toFixed(2)+'NM or '+decimalToTime(result.ta2)+'s\n\nTotal Time: '+decimalToTime(result.t)+'s');
    console.log(resultsString);
  };

// Helper function to convert degrees to radians
function toRadians(degrees) {
    return degrees * (Math.PI / 180);
}
function distanceBetweenPoints(x,y,x1,y1){
  const deltax = x1 - x;
  const deltay = y1 - y;
  return Math.sqrt(Math.pow(deltax,2)+Math.pow(deltay,2));
}
// Function to calculate Haversine distance and convert to X, Y coordinates
function latLngToXY(lat1, lon1, lat2, lon2) {
  // Convert latitudes and longitudes from degrees to radians
  lat1 = toRadians(lat1);
  lon1 = toRadians(lon1);
  lat2 = toRadians(lat2);
  lon2 = toRadians(lon2);

  // Haversine formula to calculate distance
  const deltaLat = lat2 - lat1;
  const deltaLon = lon2 - lon1;

  // Haversine distance calculation (optional, if needed)
  const a = Math.sin(deltaLat / 2) ** 2 +
            Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = EARTH_RADIUS * c; // Distance in kilometers

  // Adjusted projections based on latitude
  const x = deltaLon * EARTH_RADIUS * Math.cos((lat1 + lat2) / 2); // Adjust for the cosine of the average latitude
  const y = deltaLat * EARTH_RADIUS;

  // Return the projected X, Y coordinates
  return { x, y, distance};
}

  const isStart = (location) => {
    return location === start;
  };

  const isDestination = (location) => {
    return location === destination;
  };

  const handleInputChange = (index, key, value) => {
    const newRows = [...rows];
    newRows[index][key] = value;
    setRows(newRows);
  };

  const handleToggleChange = () => {
    setSelectStartOn(!selectStartOn);
  };

  useEffect(() => {
    const updateLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            setUserLocation([latitude, longitude]); // Update marker location
          },
          (err) => {
            setError(err.message);
          }
        );
      }
    };

    updateLocation(); // Initial location fetch
    const interval = setInterval(updateLocation, 1000); // Update every 10 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <div className="map-container sailfast">
      <button className="settings-button" onClick={toggleModal}>
        ⚙️
      </button>
      <Map
        center={initialCenter} // Keep this as the initial center
        zoom={zoom} // Fixed zoom level
        width={800}
        height={600}
      >
        {/* Always render the marker at the user's current location */}
        <Marker anchor={userLocation} payload={1} />
        <Marker anchor={startCoords} payload={1} color='green'/>
        <Marker anchor={destCoords} payload={1} color='red'/>
        {/* {[...Array(markers).keys()].map((markers, i) => {
      return <Marker key={i} width={50} anchor={markerLocations.data[i]} color={color} 
      onClick={() => setHue(hue + 20)} />;
    })} */}

      </Map>
      {error && <p>Error: {error}</p>}
        {/* Input fields for wind direction and wind speed */}
            <div className="wind-inputs">
        <input
          type="text"
          value={windDirection}
          onChange={(e) => setWindDirection(e.target.value)}
          placeholder="Wind Direction"
        />
        <input
          type="text"
          value={windSpeed}
          onChange={(e) => setWindSpeed(e.target.value)}
          placeholder="Wind Speed"
        />
      </div>
       {/* Toggle for Select Start On/Off */}
       <div className="toggle-container">
        <label>
          <input
            type="checkbox"
            checked={selectStartOn}
            onChange={handleToggleChange}
          />
          Select Start {selectStartOn ? 'On' : 'Off'}
        </label>
      </div>
      <div className='location-table'>
        <h2>Location Table</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <table>
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {locations.map((location) => (
              <tr
                key={location.name}
                onClick={() => handleLocationClick(location)}
                style={{
                  backgroundColor: isStart(location) ? 'green' : isDestination(location) ? 'red' : 'transparent',
                  cursor: 'pointer',
                }}
              >
                <td>{location.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <button onClick={handleCalculate}>Calculate</button>
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={toggleModal}>&times;</span>
            <h2>Velocity Chart</h2>
            <table>
              <thead>
                <tr>
                  <th>Wind Speed</th>
                  <th>Close Haul</th>
                  <th>Close Reach</th>
                  <th>Beam Reach</th>
                  <th>Broad Reach</th>
                  <th>Trailing Run</th>
                  <th>Run</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => (
                  <tr key={index}>
                    {Object.keys(row).map((key) => (
                      <td key={key}>
                        <input
                          type="text"
                          value={row[key]}
                          onChange={(e) => handleInputChange(index, key, e.target.value)}
                          placeholder={key}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={addRow}>Add Row</button>
          </div>
        </div>
      )}
     
     <div>
      <textarea
        value={resultsString}
        readOnly
        rows={10} // Adjust this based on how many lines you'd like to show
        cols={50} // Adjust the width
        wrap="soft" // Wraps text without hard line breaks
        style={{ width: '100%', height: '200px', fontFamily: 'monospace' }} // Optional styling
      />
    </div>

    </div>
  );
};
export default SailFast;